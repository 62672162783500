import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { ResetState } from '../features/shared';

export interface LoadingStateModel {
  [key: string]: { loading: boolean };
}

export class LoadingStart {
  static readonly type = '@loading.LoadingStart';
  /**
   * Start Loading Action for key
   * @param key unique key for loading
   */
  constructor(public key: string) {}
}

export class LoadingEnd {
  static readonly type = '@loading.LoadingEnd';
  /**
   * End Loading Action for key
   * @param key unique key for loading
   */
  constructor(public key: string) {}
}

@State<LoadingStateModel>({
  name: 'loading',
  defaults: {},
})
@Injectable()
export class LoadingState {
  @Selector() static loadingAll(state: LoadingStateModel) {
    const isLoading = Object.values(state)?.some((a) => a.loading === true);
    return isLoading ?? false;
  }

  static isLoading(key: string) {
    return createSelector([LoadingState], (state: LoadingStateModel) => {
      return state[key]?.loading ?? false;
    });
  }

  @Action(LoadingStart)
  LoadingStart(ctx: StateContext<LoadingStateModel>, action: LoadingStart) {
    const state = ctx.getState();
    ctx.patchState({ ...state, [action.key]: { loading: true } });
  }

  @Action(LoadingEnd)
  LoadingEnd(ctx: StateContext<LoadingStateModel>, action: LoadingEnd) {
    const state = ctx.getState();
    ctx.patchState({ ...state, [action.key]: { loading: false } });
  }

  @Action(ResetState)
  resetState(ctx: StateContext<LoadingStateModel>) {
    ctx.patchState({});
  }
}
